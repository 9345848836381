@use '../../assets/stylesheets/variables' as *;
@use '../../assets/stylesheets/mixins' as *;

.g-themes {
  height: 100vh;

  display: flex;
  align-items: stretch;

  @include breakpoint-down(md) {
    // transition: transform 0.2s ease-in-out;
    width: calc(#{$guides-theme-count} * 100%);
  }

  @include breakpoint-up(lg) {
    transform: translateX(0);
  }

  @include breakpoint-between(lg, xl) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.g-themes--animated {
  @include breakpoint-down(md) {
    transition: transform 0.2s ease-in-out;
  }
}

.g-themes__item {
  position: relative;
  width: 100vw;
  overflow: hidden;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
  padding: 1rem;

  > h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }

  @include breakpoint-up(lg) {
    width: auto;
    flex: 1;
  }

  @include breakpoint-between(lg, xl) {
    > svg {
      margin-bottom: 1rem;
    }
    > h1,
    > p {
      display: none;
    }
  }
}

.g-themes__item--know-myself {
  background-color: var(--know-myself);
  background-image: url('#{$img-know-myself}');
}

.g-themes__item--express-myself {
  background-color: var(--express-myself);
  background-image: url('#{$img-express-myself}');
}

.g-themes__item--be-well {
  background-color: var(--be-well);
  background-image: url('#{$img-be-well}');
}

.g-themes__item--have-adventures {
  background-color: var(--have-adventures);
  background-image: url('#{$img-have-adventures}');
}

.g-themes__item--take-action {
  background-color: var(--take-action);
  background-image: url('#{$img-take-action}');
}

.g-themes__item--skills-for-my-future {
  background-color: var(--skills-for-my-future);
  background-image: url('#{$img-skills-for-my-future}');
}

.g-themes-dots {
  position: fixed;
  top: auto;
  left: 50vw;
  transform: translateX(-50%);
  bottom: 2rem;
}

// AWARD
.gt-award {
  z-index: 1;
  isolation: isolate;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  place-content: center;

  padding: 1rem;

  > svg {
    max-width: 128px;
  }

  @include breakpoint-between(md, xl) {
    grid-template-columns: none;
  }
}

.gt-award::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.95;
}

.gt-award--bronze::before {
  background-color: var(--bronze);
}

.gt-award--silver::before {
  background-color: var(--silver);
}

.gt-award--gold::before {
  background-color: var(--gold);
}
