// @use '../../assets/stylesheets/variables' as *;
@use '../../assets/stylesheets/mixins' as *;

// CONTENT
.content {
  min-height: calc(100vh - var(--offline-alert-height));

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  background-image: url('../../assets/images/guide-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  // transition: background-image 0.3s linear;

  @include breakpoint-up(sm) {
    background-size: contain;
  }
}

.content.content--with-appbar {
  padding-top: calc(var(--appbar-height) + var(--offline-alert-height));
}

.content.content--with-toolbar {
  padding-bottom: var(--toolbar-height);
}
