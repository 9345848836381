@use 'sass:color';
@use '../../assets/stylesheets/variables' as *;

.gta-list {
  overflow-y: auto;
}

.gta-item {
  display: grid;
  grid-template-columns: 1fr 3.25rem; //clamp(2rem, 3rem, 4rem);

  background-color: var(--dark-glass);
  color: var(--dark);
  border-radius: 10px;
  margin-bottom: 1rem;

  > * {
    border-radius: 10px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > :first-child {
    display: grid;
    padding: 0.75rem 0.5rem 0.75rem 1rem;
    // cursor: pointer;
    // background-color: var(--dark-glass);
    // color: var(--dark);
  }

  > :first-child small {
    font-size: 75%;
    opacity: 0.5;
  }

  > button {
    // border-radius: 50%;
    margin: 0.68rem 1rem 0.68rem 0.5rem;
    border: 4px solid transparent;
  }

  input[type='checkbox'] {
    margin: 0 !important;

    border-style: solid;
    border-width: 3px;
    border-color: color.adjust($dark, $alpha: -0.5);
  }
}

.gta-item--completed,
.gta-item--selected {
  gap: 0.5rem;
  background-color: transparent;
}

.gta-item--completed {
  > :last-child {
    background-color: var(--success);
    color: var(--light);
  }
}

.gta-item--selected {
  // background-color: color.adjust($dark, $alpha: -0.25);
  // color: var(--light);

  > :first-child,
  > :last-child {
    background-color: var(--dark); //color.adjust($dark, $alpha: -0.25);
    color: var(--light);
  }

  > :first-child {
    padding-right: 1rem;
  }

  input[type='checkbox'] {
    border-color: color.adjust($light, $alpha: -0.25);
  }
}
