@use 'sass:map';
@use './variables' as *;

@mixin breakpoint-up($breakpoint, $orientation: null) {
  $min-width: map.get($breakpoints-min, $breakpoint);

  @if ($orientation) {
    @media (min-width: #{$min-width}) and (orientation: #{$orientation}) {
      @content;
    }
  } @else {
    @media (min-width: #{$min-width}) {
      @content;
    }
  }
}

@mixin breakpoint-down($breakpoint, $orientation: null) {
  $max-width: map.get($breakpoints-max, $breakpoint);

  @if ($orientation) {
    @media (max-width: #{$max-width}) and (orientation: #{$orientation}) {
      @content;
    }
  } @else {
    @media (max-width: #{$max-width}) {
      @content;
    }
  }
}

@mixin breakpoint-between(
  $breakpoint-min,
  $breakpoint-max,
  $orientation: null
) {
  $min-width: map.get($breakpoints-min, $breakpoint-min);
  $max-width: map.get($breakpoints-max, $breakpoint-max);

  @if ($orientation) {
    @media (min-width: #{$min-width}) and (max-width: #{$max-width}) and (orientation: #{$orientation}) {
      @content;
    }
  } @else {
    @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
      @content;
    }
  }
}

@mixin breakpoint-only($breakpoint, $orientation: null) {
  @include breakpoint-between($breakpoint, $breakpoint, $orientation);
}

@mixin m-keyboard-visible() {
  @media (max-height: 575px) {
    @content;
  }
}

// @mixin guides-theme-bg($theme-name, $light: false) {
//   $prefix: '%3Csvg width="1024" height="1036" viewBox="0 0 1024 1036" fill="none"%3E';
//   $suffix: '%3C/svg%3E%0A';
//   background-image: url('data:image/svg+xml,#{$prefix}#{$suffix}');
// }
