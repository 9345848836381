// COLOURS
$colors: primary, light, dark, warning, know-myself, express-myself, be-well,
  have-adventures, take-action, skills-for-my-future;

@each $color in $colors {
  .text-#{$color} {
    color: var(--#{$color}) !important;
  }

  .text-#{$color}-dark {
    color: var(--#{$color}-dark) !important;
  }
}

// ALIGNMENT
$alignments: center, left, right; //, justify;

@each $align in $alignments {
  .text-#{$align} {
    text-align: $align !important;
  }
}

// CASE
.text-uppercase {
  text-transform: uppercase !important;
}
