@use './assets/stylesheets/variables' as *;
@import './assets/stylesheets/normalise';
@import './assets/stylesheets/fonts';
@import './assets/stylesheets/helpers';

:root {
  // SIZES
  --offline-alert-height: 0px;
  --appbar-height: #{$appbar-height};
  --toolbar-height: #{$toolbar-height};
  // COLOURS
  --primary: #{$primary};
  --primary-70: #{$primary-70};
  --primary-20: #{$primary-20};
  --primary-dark: #{$primary-dark};
  --primary-glass: #{$primary-glass};

  --secondary: #{$secondary};
  --secondary-70: #{$secondary-70};
  --secondary-20: #{$secondary-20};
  --secondary-dark: #{$secondary-dark};
  --secondary-glass: #{$secondary-glass};

  --dark: #{$dark};
  --dark-glass: #{$dark-glass};
  --light: #{$light};
  --light-glass: #{$light-glass};

  --grey: #{$grey};
  --grey-50: #{$grey-50};
  --bronze: #{$bronze};
  --silver: #{$silver};
  --gold: #{$gold};

  --bronze-glass: #{$bronze-glass};
  --silver-glass: #{$silver-glass};
  --gold-glass: #{$gold-glass};

  --success: #{$success};
  --success-70: #{$success-70};
  --success-50: #{$success-50};
  --success-20: #{$success-20};
  --success-dark: #{$success-dark};
  --success-glass: #{$success-glass};

  --info: #{$info};
  --info-70: #{$info-70};
  --info-50: #{$info-50};
  --info-20: #{$info-20};
  --info-dark: #{$info-dark};
  --info-glass: #{$info-glass};

  --warning: #{$warning};
  --warning-70: #{$warning-70};
  --warning-50: #{$warning-50};
  --warning-20: #{$warning-20};
  --warning-dark: #{$warning-dark};
  --warning-glass: #{$warning-glass};

  --danger: #{$danger};
  --danger-70: #{$danger-70};
  --danger-50: #{$danger-50};
  --danger-20: #{$danger-20};
  --danger-dark: #{$danger-dark};
  --danger-glass: #{$danger-glass};

  --background-color: #{$background-color};
  --text-color: #{$text-color};

  // THEME AWARD COLOURS
  --know-myself: #{$know-myself};
  --know-myself-70: #{$know-myself-70};
  --know-myself-20: #{$know-myself-20};
  --know-myself-dark: #{$know-myself-dark};
  --know-myself-glass: #{$know-myself-glass};

  --express-myself: #{$express-myself};
  --express-myself-70: #{$express-myself-70};
  --express-myself-20: #{$express-myself-20};
  --express-myself-dark: #{$express-myself-dark};
  --express-myself-glass: #{$express-myself-glass};

  --be-well: #{$be-well};
  --be-well-70: #{$be-well-70};
  --be-well-20: #{$be-well-20};
  --be-well-dark: #{$be-well-dark};
  --be-well-glass: #{$be-well-glass};

  --have-adventures: #{$have-adventures};
  --have-adventures-70: #{$have-adventures-70};
  --have-adventures-20: #{$have-adventures-20};
  --have-adventures-dark: #{$have-adventures-dark};
  --have-adventures-glass: #{$have-adventures-glass};

  --take-action: #{$take-action};
  --take-action-70: #{$take-action-70};
  --take-action-20: #{$take-action-20};
  --take-action-dark: #{$take-action-dark};
  --take-action-glass: #{$take-action-glass};

  --skills-for-my-future: #{$skills-for-my-future};
  --skills-for-my-future-70: #{$skills-for-my-future-70};
  --skills-for-my-future-20: #{$skills-for-my-future-20};
  --skills-for-my-future-dark: #{$skills-for-my-future-dark};
  --skills-for-my-future-glass: #{$skills-for-my-future-glass};

  // TYPOGRAPHY
  --font-family: 'Trebuchet MS';
  --font-family-bold: 'Trebuchet MS Bold';
  // --font-size-base: 1rem;
  // BORDERS
  --border-radius-sm: #{$border-radius-sm};
  --border-width-sm: #{$border-width-sm};
  --border-radius-md: #{$border-radius-md};
}

body {
  font-family: var(--font-family);
  // font-size: var(--font-size-base);

  background-color: var(--background-color);
  color: var(--text-color);
}

body.offline {
  --offline-alert-height: 35px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-bold);
}

// TOOLTIP
.tooltip-wrapper {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  transform: translateX(-50%);
  width: max-content;
  padding: 0.5rem;
  background-color: rgb(51, 51, 51);
  color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.tooltip-wrapper:focus .tooltip {
  opacity: 1;
  transform: translate(-50%, -125%);
}

// TOASTIFY
.Toastify__toast--info {
  background-color: var(--info-70);
}

.Toastify__toast--success {
  background-color: var(--success-70);
}

.Toastify__toast--error {
  background-color: var(--danger-70);
}

.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--error {
  color: var(--text-light);

  .Toastify__toast-icon svg {
    fill: var(--light) !important;
  }
}

.Toastify__progress-bar--info,
.Toastify__progress-bar--error {
  background-color: var(--light);
}
