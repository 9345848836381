@use '../../assets/stylesheets/mixins' as *;

.content--sign-in,
.content--sign-up {
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;

  padding-top: 1rem;
  padding-bottom: 1rem;

  &.content--with-appbar {
    padding-bottom: var(--appbar-height);
  }

  @include m-keyboard-visible() {
    .logo-wrapper {
      display: none;
    }
  }
}

.content--sign-in form,
.content--sign-up form {
  // padding: 1rem;
  text-align: center;
}
