.alert {
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
}

.alert-offline {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  padding: 0.5rem;

  background-color: var(--danger);
  color: var(--light);
  border-style: none;

  text-align: center;
  // transition: transform 0.3s linear;
  // transform: translateY(-100%);

  // &.show {
  //   transform: translateY(0);
  // }
}

.alert-success {
  background-color: var(--success-20);
  color: var(--success-dark);
  border-color: var(--success);
}

.alert-info {
  background-color: var(--info-20);
  color: var(--info-dark);
  border-color: var(--info);
}

.alert-warning {
  background-color: var(--warning-20);
  color: var(--warning-dark);
  border-color: var(--warning);
}

.alert-danger {
  background-color: var(--danger-20);
  color: var(--danger-dark);
  border-color: var(--danger);
}

// SOLID COLORS
.alert-success--solid {
  background-color: var(--success);
  color: var(--light);
  border-color: var(--success);
}

.alert-info--solid {
  background-color: var(--info);
  color: var(--light);
  border-color: var(--info);
}

.alert-warning--solid {
  background-color: var(--warning);
  color: var(--light);
  border-color: var(--warning);
}

.alert-danger--solid {
  background-color: var(--danger);
  color: var(--light);
  border-color: var(--danger);
}
