.select {
  appearance: none;
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius-md);
  border: 0 solid var(--grey-50);
  background-color: var(--light);
  color: var(--text-color);
  margin-bottom: 1rem;
  line-height: 1.75;
  font-size: 1rem;

  &:focus {
    outline: var(--grey) auto 5px;
  }

  &select::-ms-expand {
    display: none;
  }
}
