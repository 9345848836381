.button {
  appearance: none;
  display: inline-block;
  width: auto;

  padding: 0.5rem 1rem;
  border-width: var(--border-width-sm);
  border-style: solid;
  border-color: transparent;
  border-radius: var(--border-radius-md);

  text-align: center;
  text-transform: uppercase;
  line-height: 1.375;
  font-size: 1rem;
  // font-weight: bold;
  font-family: var(--font-family);
  text-decoration: none;
  cursor: pointer;
}

.button--icon-button {
  display: inline-grid;
  place-items: center;

  padding: 0.25rem;
  // border-radius: 50%;
  max-width: 4rem; // 64px;

  > svg {
    max-width: 100%;
  }
}

.button--small {
  font-size: 80%;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}

.button--large {
  padding: 0.75rem 1rem;
}

// COLOURED BUTTONS
$colors: primary, success, info, warning, danger;

@each $color in $colors {
  .button--#{$color} {
    &:not(:disabled) {
      background-color: var(--#{$color});
      color: var(--light);
    }

    &:not(:disabled):hover {
      background-color: var(--#{$color}-dark);
    }

    &:focus-visible,
    &:focus {
      outline: solid var(--#{$color}-20);
    }
  }
}

.button--light {
  &:not(:disabled) {
    background-color: var(--light);
    color: var(--dark);
  }

  &:not(:disabled):hover {
    background-color: var(--light);
  }

  &:focus-visible,
  &:focus {
    outline: solid var(--light-glass);
  }
}

.button--dark {
  &:not(:disabled) {
    background-color: var(--dark);
    color: var(--light);
  }

  &:not(:disabled):hover {
    background-color: var(--dark);
  }

  &:focus-visible,
  &:focus {
    outline: solid var(--dark-glass);
  }
}

$theme-colors: know-myself, express-myself, be-well, have-adventures,
  take-action, skills-for-my-future;

@each $color in $theme-colors {
  .button--#{$color} {
    &:not(:disabled) {
      background-color: var(--#{$color});
      color: var(--light);
    }

    &:not(:disabled):hover {
      background-color: var(--#{$color}-dark);
    }

    &:focus-visible,
    &:focus {
      outline: solid var(--#{$color}-70);
    }
  }
}

// DISPLAY
.button--block {
  display: block;
  width: 100%;
}

.button--icon-button.button-block {
  display: grid;
}
