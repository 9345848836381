.dot-group {
  display: flex;
  gap: 0.25rem;
}

.dot-group--left {
  justify-content: flex-start;
}

.dot-group--center {
  justify-content: center;
}

.dot-group--right {
  justify-content: flex-end;
}

.dot {
  content: ' ';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  opacity: 0.25;
}

.dot.dot--active {
  opacity: 1;
}

.dot-group--clickable > .dot:not(.dot--active) {
  cursor: pointer;
}
