.logo-wrapper {
  margin-bottom: 2rem;
  text-align: center;

  h1 {
    margin-bottom: 0;
  }

  .slogan {
    text-transform: uppercase;
  }
}
