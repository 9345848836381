@use '../../assets/stylesheets/mixins' as *;

.appbar {
  z-index: 99;
  position: fixed;
  top: var(--offline-alert-height);
  left: 0;

  display: flex;
  place-items: center;

  width: 100%;
  height: var(--appbar-height);

  padding: 0.5rem 0.75rem 0.5rem 1rem;

  background-color: var(--light);
  color: var(--dark);

  // transition: background-color 0.2s linear, color 0.2s linear;

  > span,
  > .appbar__spacer {
    flex: 1;
  }

  > span:not(.appbar__spacer) {
    font-size: 110%;
    text-transform: uppercase;
    // font-weight: bold;
    padding: 0 0.5rem;
  }

  .button {
    min-width: 2rem;
    background-color: transparent;
  }

  // @include breakpoint-up(sm) {
  //   > button {
  //     width: 4rem;
  //   }
  // }
}

.appbar--static {
  position: relative;
}

.appbar.appbar--no-left-button {
  padding-left: 2.5rem;
}

.appbar.appbar--no-right-buttons {
  padding-right: 2.5rem;
}

// .offline .appbar {
//   top: calc(var(--offline-alert-height) + 35px);
// }
