*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  min-height: 100vh;
}

body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0.5rem;
}

p {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
