.checkbox {
  isolation: isolate;

  > input[type='checkbox'] {
    appearance: unset;
    float: left;
    width: 1.5rem;
    height: 1.5rem;

    border-style: none;
    // border-width: 1px;
    // border-color: var(--grey);
    border-radius: var(--border-radius-sm);

    background-color: var(--grey-50);
    margin-right: 1.5rem;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:focus-visible {
      outline: var(--grey) auto 5px;
    }
  }

  > input:checked[type='checkbox'] {
    background-color: var(--dark);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  > label {
    display: block;
    line-height: 1.65;
  }
}
