@use 'sass:color';

@use '../../assets/stylesheets/variables' as *;
@use '../../assets/stylesheets/mixins' as *;

.gt-theme {
  width: 100vw;
  overflow: hidden;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include breakpoint-up(md) {
    background-size: contain;
    background-position: center left;
  }
}

.gt-theme--know-myself {
  background-color: var(--know-myself-20);
  background-image: url('#{$img-know-myself-20}');
}

.gt-theme--express-myself {
  background-color: var(--express-myself-20);
  background-image: url('#{$img-express-myself-20}');
}

.gt-theme--be-well {
  background-color: var(--be-well-20);
  background-image: url('#{$img-be-well-20}');
}

.gt-theme--have-adventures {
  background-color: var(--have-adventures-20);
  background-image: url('#{$img-have-adventures-20}');
}

.gt-theme--take-action {
  background-color: var(--take-action-20);
  background-image: url('#{$img-take-action-20}');
}

.gt-theme--skills-for-my-future {
  background-color: var(--skills-for-my-future-20);
  background-image: url('#{$img-skills-for-my-future-20}');
}

// .gt-theme .accordion-toggle--open {
//   position: sticky;
//   top: var(--appbar-height);
// }

.uma-item {
  display: grid;
  place-content: center;
  place-items: center;

  padding-top: calc(100% - 1rem);
  border-radius: 50%;
  border-width: 0.5rem;
  border-style: solid;

  border-color: transparent;
  background-color: color.adjust($light, $alpha: -0.5);
}

.uma-item--add {
  position: relative;
  //   border-width: 5px;
  //   border-style: solid;
  > svg,
  > .button {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }

  > .button {
    border-radius: 50%;
    // width: 100%;
    // height: 100%;
    padding: 0.5rem;
  }
}
