@use '../../assets/stylesheets/mixins' as *;

.modal-wrapper {
  position: fixed;
  top: 0; //var(--offline-alert-height);
  left: 0;
  bottom: 0;
  width: 100%;
  // height: calc(100vh - var(--offline-alert-height));
  background: rgba(0, 0, 0, 0.95);
  z-index: 9990;
  opacity: 0;
  transition: opacity 0.2s linear;

  &.fade-in {
    opacity: 1;
  }

  &:not(.fade-in) {
    transition-delay: 300ms;
  }

  @include breakpoint-up(sm) {
    padding-top: 5vh;
  }
}

.modal {
  z-index: 9999;
  position: absolute;
  top: var(--offline-alert-height);

  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - var(--offline-alert-height));
  transform: scale(0);
  transition: transform 0.2s linear;

  .appbar {
    position: relative;
    top: 0 !important;
  }

  @include breakpoint-up(sm) {
    top: auto;
    max-width: 428px;
    height: auto;
    max-height: 90vh;
    border: 5px solid var(--dark-glass);
    border-radius: 10px;
    margin-left: 50%;
    transform: translateY(-100%) translateX(-50%);

    .appbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.modal--show {
  transform: scale(100%);
  &.in {
    transition-delay: 100ms;
  }

  @include breakpoint-up(sm) {
    transform: translateY(0) translateX(-50%);
  }
}

.modal__body {
  flex: 1;
  // padding-top: var(--appbar-height);
  overflow-y: auto;

  @include breakpoint-up(sm) {
    border-radius: 10px;
  }
}

.modal__footer {
  @include breakpoint-up(sm) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    & ~ .modal__body {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
