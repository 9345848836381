// COLOR
.bg-none {
  background-color: transparent;
}

$bg-colors: primary, light, dark, success, info, warning, danger, know-myself,
  express-myself, be-well, have-adventures, take-action, skills-for-my-future,
  bronze, silver, gold;

@each $colour in $bg-colors {
  .bg-#{$colour} {
    background-color: var(--#{$colour}) !important;
  }

  .bg-#{$colour}-70 {
    background-color: var(--#{$colour}-70) !important;
  }

  .bg-#{$colour}-20 {
    background-color: var(--#{$colour}-20) !important;
  }
}

// GLASS
$glass-colors: primary, light, dark, success, info, warning, danger, know-myself,
  express-myself, be-well, have-adventures, take-action, skills-for-my-future,
  bronze, silver, gold;

@each $colour in $glass-colors {
  .bg-#{$colour}--glass {
    background-color: var(--#{$colour}-glass) !important;
  }
}

// DISPLAY
.d-block {
  display: block !important;
  width: 100% !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

// FLEX
.flex-1 {
  flex: 1 !important;
}

.flex-column {
  flex-direction: column !important;
}

// ALIGNMENT
// .align-center {
//   align-items: center !important;
// }

// BORDERS
.br-rounded {
  border-radius: 10px !important;
}

.br-circle {
  border-radius: 50% !important;
}

// SPACING
@for $space from 0 to 4 {
  // PADDINGS
  .p-#{$space} {
    padding: #{$space * 0.5}rem !important;
  }

  .pt-#{$space} {
    padding-top: #{$space * 0.5}rem !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space * 0.5}rem !important;
  }

  .pl-#{$space} {
    padding-left: #{$space * 0.5}rem !important;
  }

  .pr-#{$space} {
    padding-right: #{$space * 0.5}rem !important;
  }

  .px-#{$space} {
    padding-left: #{$space * 0.5}rem !important;
    padding-right: #{$space * 0.5}rem !important;
  }

  .py-#{$space} {
    padding-top: #{$space * 0.5}rem !important;
    padding-bottom: #{$space * 0.5}rem !important;
  }

  // MARGINS
  .mt-#{$space} {
    margin-top: #{$space * 0.5}rem !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space * 0.5}rem !important;
  }

  .my-#{$space} {
    margin-top: #{$space * 0.5}rem !important;
    margin-bottom: #{$space * 0.5}rem !important;
  }

  .mx-#{$space} {
    margin-left: #{$space * 0.5}rem !important;
    margin-right: #{$space * 0.5}rem !important;
  }
}
