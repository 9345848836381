@use '../../assets/stylesheets/mixins' as *;

$asset-path: '../../assets/images/errors/';

.error-page {
  display: flex;
  place-items: flex-end;
  place-content: center;
  font-size: 1rem;

  width: 100%;
  min-width: 10rem;
  min-height: 100vh;

  padding: 0.5rem 0.5rem 5rem;
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;

  text-align: center;

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-family: var(--font-family);
    font-size: 1.5rem;
  }

  button {
    width: 75%;
    max-width: 16rem;
  }

  > div {
    min-width: 10rem;
  }

  @include breakpoint-down(sm, landscape) {
    place-items: center;
    place-content: flex-start;

    padding: 1.5rem 3rem !important;
    text-align: initial !important;
    background-position: right center;

    > div {
      max-width: 50%;
    }
  }

  @include breakpoint-up(md) {
    place-items: center;
    place-content: flex-start;

    padding: 3rem;
    text-align: initial !important;
    background-position: right center;
    background-size: contain;

    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 1.75rem;
    }

    > div {
      max-width: 50%;
    }
  }

  @include breakpoint-up(lg) {
    padding: 4rem;
    background-position: right center;

    h1 {
      font-size: 7rem;
    }

    h2 {
      font-size: 2rem;
    }

    button {
      width: 60%;
    }

    > div {
      max-width: 40%;
    }
  }

  @include breakpoint-up(xl) {
    padding: 5rem;

    h1 {
      font-size: 8rem;
    }
  }

  @include breakpoint-up(xxl) {
    padding: 6rem;
  }
}

.error-page--404 {
  background-color: var(--warning-20);
  background-image: url('#{$asset-path}404-portrait.png');

  @include breakpoint-down(sm, landscape) {
    background-image: url('#{$asset-path}404-landscape.png');
  }

  @include breakpoint-up(md) {
    background-image: url('#{$asset-path}404-md.png');
  }

  @include breakpoint-up(xl) {
    background-image: url('#{$asset-path}404.png');
  }
}

.error-page--generic {
  background-color: var(--danger-20);
  background-image: url('#{$asset-path}generic-portrait.png');

  @include breakpoint-down(sm, landscape) {
    background-image: url('#{$asset-path}generic-landscape.png');
  }

  @include breakpoint-up(md) {
    background-image: url('#{$asset-path}generic-md.png');
  }

  @include breakpoint-up(xl) {
    background-image: url('#{$asset-path}generic.png');
  }
}
