.grid {
  display: grid;
  gap: 0.5rem;
}

@for $num from 2 to 6 {
  .grid-columns-#{$num} {
    grid-template-columns: repeat(#{$num}, 1fr);
  }
}

.grid--flex {
  display: flex;
  flex-wrap: wrap;
  // gap: 0;
}

.grid-item {
  border-radius: 10px;
}
