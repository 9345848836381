@use '../../assets/stylesheets/mixins' as *;

.toolbar {
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: var(--toolbar-height);
  padding: 0.5rem 0.75rem;

  background-color: var(--light);
  color: var(--dark);

  > button,
  > a {
    flex: 1;
    max-width: 4rem;
    border-radius: 50%;

    &:focus {
      outline: none;
    }
  }

  > button > small {
    font-size: 0.75rem;
  }

  @include breakpoint-up(md) {
    justify-content: center; //space-evenly;
    gap: 0.5rem;
  }
}

.toolbar > div {
  display: grid;
  place-content: center;
}
.toolbar .button--active {
  position: relative;
  isolation: isolate;
  transform: scale(1.375) translateY(-15%);

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    z-index: -1;
    width: 115%;
    height: 115%;
    border-radius: 50%;
    background-color: var(--light);
  }

  // &::after {
  //   content: attr(data-name);
  //   z-index: 1;
  //   bottom: -1rem;
  //   width: 150%;
  //   height: 1rem;
  //   color: var(--dark);
  //   font-size: 50%;
  // }

  // small {
  //   position: absolute;
  //   font-size: 6px;
  //   width: 100%;
  //   color: var(--dark);
  //   bottom: -1.25rem;
  // }
}
