.input {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius-md);
  border: 0 solid var(--grey-50);
  // border: none;
  background-color: var(--light);
  margin-bottom: 1rem;
  line-height: 1.75;
  font-size: 1rem;

  &:focus {
    outline: var(--grey) auto 5px;
  }
}

.input--password {
  position: relative;
}

.input-group {
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;

  > .input {
    flex: 1;
    margin-bottom: 0;
  }

  > * {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.input-group--addon {
  display: grid;
  place-items: center;
  place-content: center;

  padding: 0.5rem;
  background-color: var(--grey-50);
  color: var(--text-color);
  border-radius: var(--border-radius-md);
}
