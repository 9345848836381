$font-path: '../fonts/';

@font-face {
  font-family: 'Trebuchet MS';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS'),
    url('#{$font-path}trebuchet-ms.woff') format('woff');
}

@font-face {
  font-family: 'Trebuchet MS Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS Italic'),
    url('#{$font-path}trebuchet-ms-italic.woff') format('woff');
}

@font-face {
  font-family: 'Trebuchet MS Bold';
  src: url('#{$font-path}trebuchet-ms-bold.eot'); /* IE9*/
  src: url('#{$font-path}trebuchet-ms-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$font-path}trebuchet-ms-bold.woff2') format('woff2'),
    /* chrome、firefox */ url('#{$font-path}trebuchet-ms-bold.woff')
      format('woff'),
    /* chrome、firefox */ url('#{$font-path}trebuchet-ms-bold.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('#{$font-path}trebuchet-ms-bold.svg#Trebuchet MS Bold') format('svg'); /* iOS 4.1- */
}
