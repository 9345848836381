// @use '../../assets/stylesheets/variables' as *;

.accordion-toggle {
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  line-height: 1.5;

  background-color: var(--dark-glass);
  color: var(--dark);
}

.accordion-toggle__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  opacity: 0.75;
}

.accordion-toggle--open {
  margin-bottom: 1rem;

  .accordion-toggle__icon {
    transform: rotate(180deg) translateY(50%);
    opacity: 1;
  }
}
